<template>
  <section class="views">
    <!-- header -->
    <headers select="9"></headers>

    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner_team.jpg')"
      />
      <div class="posters_ewm" v-if="false">
        <div class="ewm">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/csewm.png')" alt="" />
            </div>
            <div class="ewm3_value">掃碼報名比賽</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="viewsBoxTab">
        <div class="viewsTab">
          <ul>
            <li
              v-for="(item, index) in tabColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select == index ? 'select' : ''"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="viewTabContent">
        <div class="ulsTab" ref="tabSlideTwo">
          <ul>
            <li
              v-for="(item, index) in tabColumns[select].dataTab"
              :key="index"
              :class="selectTwo == index ? 'select' : ''"
              @click="tabButTwo(index)"
              :ref="`tab${index}RefTwo`"
            >
              <div>{{ item.name }}</div>
              <div>（{{ item.fzhu }}）</div>
            </li>
          </ul>
        </div>

        <div class="box">
          <div v-if="select == 0" style="margin-bottom: 15px;margin-top:-16px;color: #fff;font-size: 18px;font-weight:bold;">排名不分先後</div>
          <!-- <ul v-if="select == 2">
            <div class="wu">
              <img :src="require('@/assets/image/hodgepodge/wu.png')" alt="" />
              <span> 當前階段成績尚未揭曉哦 </span>
            </div>
          </ul> -->
          <ul v-if="select === 0">
            <li class="weiyitop">
              <div>序號</div>
              <div>學校/機構名</div>
            </li>
            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>{{ item.competitionId }}</div>
              <div>{{ item.agencyName }}</div>
            </li>
          </ul>
          
          <ul v-if="select === 1">
            <li class="weiyitop_1">
              <div>序號</div>
              <div>學校/機構名</div>
              <div>獎項</div>
            </li>
            <li class="lis_1" v-for="(item, index) in queryUserList" :key="index">
              <div>{{ item.competitionId }}</div>
              <div>{{ item.agencyName }}</div>
              <div>
                {{
                  item.ranking == 1
                    ? '金獎'
                    : item.ranking == 2
                    ? '銀獎'
                    : item.ranking == 3
                    ? '銅獎'
                    : item.ranking > 3
                    ? '優異獎'
                    : ''
                }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 佔位 -->
      <div style="margin-top: 20px; width: 100%"></div>
    </div>
  </section>
</template>
	 
	<script>
import headers from '@/components/header/pc_header_team'
export default {
  name: 'signup',
  data() {
    return {
      tabColumns: [
        // {
        //   id: '0',
        //   name: '入圍總決賽名單',
        //   dataTab: [
        //     {
        //       name: '幼稚園組',
        //       fzhu: 'K1-K3'
        //     },
        //     {
        //       name: '小學初級組',
        //       fzhu: 'P1-P3'
        //     },
        //     {
        //       name: '小學高級組',
        //       fzhu: 'P4-P6'
        //     },
        //     {
        //       name: '中學組',
        //       fzhu: 'S1-S6'
        //     }
        //   ]
        // },
        {
          id: 0,
          name: '團體賽優異獎',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P3'
            },
            {
              name: '小學高級組',
              fzhu: 'P4-P6'
            },
            {
              name: '中學組',
              fzhu: 'S1-S6'
            }
          ]
        },
        {
          id: 1,
          name: '總決賽成績',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P3'
            },
            {
              name: '小學高級組',
              fzhu: 'P4-P6'
            },
            {
              name: '中學組',
              fzhu: 'S1-S6'
            }
          ]
        }
      ],
      select: 0,
      selectTwo: 0,
      queryUserList: [],
      finalists: [
        {
          group: 1,
          data: [
            {
              competitionId: 61,
              agencyName: '安基司幼稚園暨國際幼兒園（粉嶺）',
              ranking: 1
            },
            {
              competitionId: 41,
              agencyName: '深圳香港培僑書院龍華信義學校',
              ranking: 2
            },
            {
              competitionId: 64,
              agencyName: '救世軍蘇屋幼稚園',
              ranking: 3
            },
          ]
        },
        {
          group: 2,
          data: [
            {
              competitionId: 18,
              agencyName: '香港童軍總會東九龍第137旅',
              ranking: 1
            },
            {
              competitionId: 3,
              agencyName: '基督教神召會梁省德小學',
              ranking: 2
            },
            {
              competitionId: 59,
              agencyName: '鳳溪創新小學',
              ranking: 3
            },
          ]
        },
        {
          group: 3,
          data: [
            {
              competitionId: 4,
              agencyName: '基督教神召會梁省德小學',
              ranking: 1
            },
            {
              competitionId: 72,
              agencyName: '三水同鄉會禤景榮學校',
              ranking: 2
            },
            {
              competitionId: 68,
              agencyName: '聖公會仁立紀念小學',
              ranking: 3
            },
          ]
        },
        {
          group: 4,
          data: [
            {
              competitionId: 58,
              agencyName: '香港神託會培基書院',
              ranking: 1
            },
            {
              competitionId: 73,
              agencyName: '廣州暨大港澳子弟學校',
              ranking: 2
            },
            {
              competitionId: 83,
              agencyName: '思凌教育有限公司',
              ranking: 3
            },
          ]
        },
      ],
      meritList: [
        {
          group: 1,
          data: [
            {
              competitionId: 12,
              agencyName: '東華三院馬陳景霞幼稚園'
            },
            {
              competitionId: 14,
              agencyName: '嘉德麗中英文幼稚園'
            },
            {
              competitionId: 80,
              agencyName: '遵道幼稚園'
            },
            {
              competitionId: 5,
              agencyName: '綠茵英文（國際）幼稚園（將軍澳）'
            },
            {
              competitionId: 37,
              agencyName: '培僑國際幼稚園'
            },
            {
              competitionId: 29,
              agencyName: '東華三院馬陳家歡幼稚園'
            },
            {
              competitionId: 54,
              agencyName: '東華三院譚錦球伉儷幼稚園'
            },
            {
              competitionId: 79,
              agencyName: '聖公會荊冕堂葵涌幼稚園'
            },
          ]
        },
        {
          group: 2,
          data: [
            {
              competitionId: 27,
              agencyName: '中華基督教會基法小學（油塘）'
            },
            {
              competitionId: 25,
              agencyName: '慈航學校'
            },
            {
              competitionId: 17,
              agencyName: '鳳溪第一小學'
            },
            {
              competitionId: 9,
              agencyName: '中華基督教會元朗真光小學'
            },
            {
              competitionId: 74,
              agencyName: '安基司學校'
            },
            {
              competitionId: 77,
              agencyName: '路德會呂祥光小學'
            },
          ]
        },
        {
          group: 3,
          data: [
            {
              competitionId: 60,
              agencyName: '鳳溪創新小學'
            },
            {
              competitionId: 67,
              agencyName: '聖公會仁立紀念小學'
            },
            {
              competitionId: 30,
              agencyName: '沙田崇真學校'
            },
            {
              competitionId: 34,
              agencyName: '廣州暨大港澳子弟學校'
            },
            {
              competitionId: 75,
              agencyName: '深圳香港培僑書院龍華信義學校'
            },
            {
              competitionId: 39,
              agencyName: '香港南區官立小學'
            },
            {
              competitionId: 22,
              agencyName: '九龍城浸信會禧年（恩平）小學'
            },
          ]
        },
        {
          group: 4,
          data: [
            {
              competitionId: 84,
              agencyName: '中華基督教會燕京書院'
            },
            {
              competitionId: 19,
              agencyName: '聖公會林護紀念中學'
            },
            {
              competitionId: 50,
              agencyName: '鳳溪第一中學'
            },
            {
              competitionId: 56,
              agencyName: '仁濟醫院董之英紀念中學'
            },
            {
              competitionId: 65,
              agencyName: '東華三院郭一葦中學'
            },
            {
              competitionId: 52,
              agencyName: '鳳溪第一中學'
            },
            {
              competitionId: 33,
              agencyName: '中華基督教會何福堂書院'
            },
          ]
        },
      ],
    }
  },
  computed: {},
  components: {
    headers
  },
  watch: {},
  created() {},
  mounted() {
    this.tabButTwo(0)
  },
  methods: {
    tabButTwo(e) {
      this.selectTwo = e
      this.queryUserList = []
      let groupId = this.selectTwo + 1
      if (this.select === 0) {
        this.meritList.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }else if(this.select === 1){
        this.finalists.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }
    },
    tabBut(e) {
      this.select = e
      this.queryUserList = []
      let groupId = this.selectTwo + 1
      if (this.select === 0) {
        this.meritList.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }else if(this.select === 1){
        this.finalists.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }
    },
    validator_email(val) {
      const email = val.trim()
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim()
      return /^[0-9]*$/.test(phone)
    },
    // getScoreList() {
    //   this.$toast.loading({
    //     message: '正在查詢中...',
    //     duration: 0,
    //     forbidClick: true
    //   })
    //   let groupId = this.selectTwo + 1
    //   let scoreType = this.select + 1
    //   let params =
    //     '?groupId=' +
    //     groupId
    //   this.$axios
    //     .get(this.$api.promotionList + params)
    //     .then((response) => {
    //       this.$toast.clear()
    //         this.queryUserList = response.data.rows
          
    //     })
    //     .catch((error) => {
    //       //请求失败
    //       this.$toast.fail('error')
    //       console.log(error)
    //     })
    // },
  }
}
</script>
	
	<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;

  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      width: 1024px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: right;

      .ewm {
        width: 161px;
        height: 189px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 8px;
        box-sizing: border-box;

        .ewm2 {
          width: 100%;
          height: 100%;
          padding: 6px;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;

          .ewm3 {
            width: 132px;
            height: 132px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: auto;

    .viewsBoxTab {
      width: 100%;
      position: relative;
      background: #d34a00;

      .viewsTab {
        width: 100%;
        max-width: 1024px;
        margin: auto;
        box-sizing: border-box;
        display: flex;

        ul {
          width: 100%;
          height: 64px;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          li {
            width: 152px;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            flex: 0 0 auto;
            background: #d34a00;
            cursor: pointer;

            div {
              width: 100%;
            }

            div:nth-child(1) {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.33rem;
            }

            div:nth-child(2) {
              font-size: 11px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 13px;
            }
          }

          .select {
            font-size: 16px;
            font-weight: 600;
            color: #903300;
            padding: 8px 0;
            background: url('~@/assets/image/hodgepodge/tabbuttom.png')
              no-repeat;
            background-size: 100% 100%;

            div:nth-child(1) {
              font-size: 16px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
              line-height: 16px !important;
            }

            div:nth-child(2) {
              font-size: 13px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
              line-height: 15.5px !important;
            }
          }
        }
      }
    }

    .viewTabContent {
      width: 100%;
      max-width: 1024px;
      margin: auto;
      margin-top: 36px;
      box-sizing: border-box;

      .ulsTab::-webkit-scrollbar {
        display: none;
      }

      .ulsTab {
        width: 100%;
        box-sizing: border-box;

        ul {
          // width: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          li {
            margin: 0 20px;
            // margin-right: 38px;
            width: 130px;
            height: 66px;
            background: #ffffff;
            border: 2px solid #ffffff;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 19px;
            text-align: center;
            border-radius: 16px;
            cursor: pointer;

            div:nth-child(1) {
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 19px;
            }

            div:nth-child(2) {
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              margin-top: 2px;
            }
          }
          .select {
            background: #fdd472 !important;

            div:nth-child(1) {
              font-size: 16px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 19px;
            }

            div:nth-child(2) {
              font-size: 13px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
            }
          }
        }
      }

      .box {
        width: 700px;
        padding-top: 64px;
        margin: auto;
        box-sizing: border-box;

        ul::-webkit-scrollbar {
          display: none;
        }

        ul {
          width: 100%;
          height: 70vh;
          border-radius: 16px;
          background: #ffffff;
          border: 0.06rem solid #ffffff;
          box-sizing: border-box;
          overflow-x: hidden;
          position: relative;

          .wei {
            width: 100%;
            height: 46px;
          }

          .weiyitop {
            width: 100%;
            height: 46px;
            background: #ffb559;
            display: flex;
            position: sticky;
            left: 0;
            top: 0;

            div {
              // width: 33%;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #a94800;
              line-height: 46px;
            }
            div:nth-child(1) {
              width: 40%;
            }
            div:nth-child(2) {
              width: 60%;
              text-align: left;
            }
          }
          .weiyitop_1 {
            width: 100%;
            height: 46px;
            background: #ffb559;
            display: flex;
            position: sticky;
            left: 0;
            top: 0;

            div {
              width: 33%;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #a94800;
              line-height: 46px;
            }
            // div:nth-child(1) {
            //   width: 40%;
            // }
            // div:nth-child(2) {
            //   width: 60%;
            //   text-align: left;
            // }
          }

          .lis {
            width: 100%;
            height: 46px;
            margin: auto;
            background: #ffffff;
            display: flex;
            border-bottom: 1px;

            div:nth-child(1) {
              width: 40%;
              text-align: center;
            }
            div:nth-child(2) {
              width: 60%;
              text-align: left;
            }
            div {
              // width: 33%;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 46px;
              text-align: center;
            }
          }
          .lis_1 {
            width: 100%;
            height: 46px;
            margin: auto;
            background: #ffffff;
            display: flex;
            border-bottom: 1px;
            div {
              width: 33%;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 46px;
              text-align: center;
            }
          }

          .wu {
            width: 100%;
            // height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;
            padding-top: 100px;

            img {
              width: 200px;
              margin-bottom: 30px;
            }

            span {
              width: 100%;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>